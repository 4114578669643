import { MortgagePageEnum } from '@app/sign-up/enums/mortgage-page.enum';
import { PropertyPageEnum } from '@app/sign-up/enums/property-page.enum';
import { SmartSearchPageEnum } from '@app/sign-up/enums/smart-search-page.enum';
import { UserPageEnum } from '@app/sign-up/enums/user-page.enum';
import { FlowStepsModel } from '@app/sign-up/models/flow-step.model';
import { paths } from '@platform/paths';

export const MOVING_HOME_FLOW = {
  SmartSearchBuyingProcessStage: 0,
  SmartSearchBasicDetails: 1,
  SmartSearchInitialRate: 2,
  SmartSearchLiveResults: 3,
  PropertyAddress: 4,
  PropertyOverview: 5,
  PropertyAdditionalInfo: 6,
  MortgageLender: 7,
  MortgageRateAndPeriod: 8,
  MortgageBorrowedAmountAndTerm: 9,
  MortgageMonthlyPayment: 10,
  MortgageProductMatch: 11,
  UserCreate: 12,
} as const;

export const MOVING_HOME_FLOW_STEPS: FlowStepsModel<typeof MOVING_HOME_FLOW> = {
  [MOVING_HOME_FLOW.SmartSearchBuyingProcessStage]: {
    step: MOVING_HOME_FLOW.SmartSearchBuyingProcessStage,
    name: 'SmartSearchBuyingProcessStage',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.BuyingProcessStage,
    },
  },
  [MOVING_HOME_FLOW.SmartSearchBasicDetails]: {
    step: MOVING_HOME_FLOW.SmartSearchBasicDetails,
    name: 'SmartSearchBasicDetails',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.BasicDetails,
    },
  },
  [MOVING_HOME_FLOW.SmartSearchInitialRate]: {
    step: MOVING_HOME_FLOW.SmartSearchInitialRate,
    name: 'SmartSearchInitialRate',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.InitialRate,
    },
  },
  [MOVING_HOME_FLOW.SmartSearchLiveResults]: {
    step: MOVING_HOME_FLOW.SmartSearchLiveResults,
    name: 'SmartSearchLiveResults',
    page: {
      route: paths.signUp.SmartSearch,
      name: SmartSearchPageEnum.LiveResults,
    },
  },
  [MOVING_HOME_FLOW.PropertyAddress]: {
    step: MOVING_HOME_FLOW.PropertyAddress,
    name: 'PropertyAddress',
    page: {
      route: paths.signUp.Property,
      name: PropertyPageEnum.Address,
    },
  },
  [MOVING_HOME_FLOW.PropertyAdditionalInfo]: {
    step: MOVING_HOME_FLOW.PropertyAdditionalInfo,
    name: 'PropertyAdditionalInfo',
    page: {
      route: paths.signUp.Property,
      name: PropertyPageEnum.AdditionalInfo,
    },
  },
  [MOVING_HOME_FLOW.PropertyOverview]: {
    step: MOVING_HOME_FLOW.PropertyOverview,
    name: 'PropertyOverview',
    page: {
      route: paths.signUp.Property,
      name: PropertyPageEnum.Overview,
    },
  },
  [MOVING_HOME_FLOW.MortgageLender]: {
    step: MOVING_HOME_FLOW.MortgageLender,
    name: 'MortgageLender',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.Lender,
    },
  },
  [MOVING_HOME_FLOW.MortgageRateAndPeriod]: {
    step: MOVING_HOME_FLOW.MortgageRateAndPeriod,
    name: 'MortgageRateAndPeriod',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.RateAndPeriod,
    },
  },
  [MOVING_HOME_FLOW.MortgageBorrowedAmountAndTerm]: {
    step: MOVING_HOME_FLOW.MortgageBorrowedAmountAndTerm,
    name: 'MortgageBorrowedAmountAndTerm',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.BorrowedAmountAndTerm,
    },
  },
  [MOVING_HOME_FLOW.MortgageMonthlyPayment]: {
    step: MOVING_HOME_FLOW.MortgageMonthlyPayment,
    name: 'MortgageMonthlyPayment',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.MonthlyPayment,
    },
  },
  [MOVING_HOME_FLOW.MortgageProductMatch]: {
    step: MOVING_HOME_FLOW.MortgageProductMatch,
    name: 'MortgageProductMatch',
    page: {
      route: paths.signUp.Mortgage,
      name: MortgagePageEnum.ProductMatch,
    },
  },
  [MOVING_HOME_FLOW.UserCreate]: {
    step: MOVING_HOME_FLOW.UserCreate,
    name: 'UserCreate',
    page: {
      route: paths.signUp.User,
      name: UserPageEnum.UserCreate,
    },
  },
};
